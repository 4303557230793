































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FileDownload } from '@/types/cms/components';
import AlertModule, { AlertType } from '@/store/modules/alert';
import { handleFile } from '@/utils/cms/formhelper';

/**
 * CMSAdminComponentFileDownloadFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSAdminComponentFileDownloadFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the pageFile data
    @Prop({type: Object as () => FileDownload}) readonly editedFileItem: FileDownload | undefined;

    // Prop that holds the id of the edited pageFile
    @Prop(Number) readonly editedFileIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'File').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the pageFile
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async saveFile() {
        if (this.editedFileItem) {
            handleFile(
                this.dialogMode,
                this.editedFileItem.fileUrl,
                this.editedFileItem.fileObj
            )
            .then(fileResponse => {
                if (
                    fileResponse.data.status !== 'success' ||
                    !('fileUrl' in fileResponse.data.data)
                ) {
                    const errorMessage = this.$t('alerts.error.' + fileResponse.data.message).toString();
                    AlertModule.showAlert({ 
                        type: AlertType.ERROR,
                        message: errorMessage 
                    });

                    return;
                }
                
                this.resetFormValidation();
                this.$emit('fileSaved', 
                    this.dialogMode, 
                    this.editedFileIndex,
                    { ...this.editedFileItem, fileUrl: fileResponse.data.data.fileUrl }
                );
            })
            .catch(err => {
                console.error(err);
            });
        }
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
